h1 {
    font-size: 1.3em;
}
h2 {
    font-size: 1.2em;
}
h3 {
    font-size: 1.1em;
}

p {
    font-size: 0.9em;
    margin: 2px;
}

ul, ol {
    font-size: 0.9em;
    margin-top: 0;
    margin-bottom: 12px;
}
